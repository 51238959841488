/* eslint-disable no-unused-expressions */
import React from "react"
import Helmet from "react-helmet"
import { graphql } from 'gatsby'
import Layout from "../components/layout"

class LinksPage extends React.Component {
    render() {
        return (
            <Layout>
                <Helmet>
                    <title>Links — {this.props.data.site.siteMetadata.title}</title>
                    <meta name="description" content={"A collection of my favourite links."} />
                </Helmet>
                <div className="flex">

                    <div className="xs:max-w-xs md:max-w-2xl mx-auto">

                        <div className="relative px-12 bg-white shadow-lg rounded-2xl p-10">
                            <div className="relative shadow mx-auto h-40 w-40 border-white rounded-full overflow-hidden border-4">
                                <img className="object-cover w-full h-full" src="/assets/me.jpeg" />
                            </div>
                            <h1 className="text-lg text-center text-gray-700 font-regular py-2 pb-10">
                                @imdadcodes
                    </h1>
                            <div className="max-w-lg">
                                <button className="w-full uppercase mx-auto shadow bg-green-700 hover:bg-green-800 focus:shadow-outline focus:outline-none text-white text-md py-3 px-10 mb-5 rounded"><a href="/get-free-python-in-100-chunks-guide">FREE Python Guide</a></button>
                                <button className="w-full uppercase mx-auto shadow bg-green-600 hover:bg-green-700 focus:shadow-outline focus:outline-none text-white text-md py-3 px-10 mb-5 rounded"><a href="https://amzn.to/2MAzDmn">Python Programming</a></button>
                                <button className="w-full uppercase mx-auto shadow bg-green-600 hover:bg-green-700 focus:shadow-outline focus:outline-none text-white text-md py-3 px-10 mb-5 rounded"><a href="https://www.buymeacoffee.com/imdadcodes/e/21270">Ask A Question</a></button>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        )
    }
}

export default LinksPage

export const pageQuery = graphql`
  query LinksPageQuery{
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`